/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from '../../config/config';
import { IReservation, IWorker } from '../../config/interface';
import { account, activeRegion } from '../../entities/session';
import {
    twilioWorkerToken,
    twilioWorkspaceToken,
    workerActivityList,
    workerStatus,
} from '../../entities/twilio';
import useTwilio, { TWILIO_EVENT } from '../../hooks/useTwilio';
import { fetchTwilioToken } from '../../services/apiService';
import ConfirmDialog from '../ConfirmDialog';
import VerticalCenter from './VerticalCenter';

export const TwilioShell = ({ children, onStatusUpdate }) => {
    const twWorkspaceToken = twilioWorkspaceToken.use();
    const twWorkerToken = twilioWorkerToken.use();
    const region: any = activeRegion.use();
    const accountData: any = account.use();
    const currentStatus = workerStatus.use();

    const twilio = useTwilio();

    const [workerToken, setWorkerToken] = useState<any>(twWorkerToken);
    const [workspaceToken, setWorkspaceToken] = useState<any>(twWorkspaceToken);
    const [workspaceObject, setWorkspaceObject] = useState<any>(null);
    const [workerObject, setWorkerObject] = useState<any>(null);
    const [workerRegistered, setWorkerRegistered] = useState<boolean>(false);
    const [activityList, setActivityList] = useState<any>(null);
    const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);
    const [hasTokenRenewed, setHasTokenRenewed] = useState<boolean>(false);
    const [newTask] = useState<any>(null);
    // const [newTask, setNewTask] = useState<any>(null);
    const [acceptedTask, setAcceptedTask] = useState<any>(null);

    function useRefreshToken(region, workerSid): any {
        return useQuery(
            ['twilio', region, workerSid],
            () => {
                return fetchTwilioToken({ region, workerSid });
            },
            {
                enabled: region && workerSid && isTokenExpired ? true : false,
            }
        );
    }
    const {
        data: twilioData,
        error: twilioError,
        isFetching: isTwilioFetching,
    } = useRefreshToken(region, accountData?.sid);

    if (!isTwilioFetching && !twilioError && !workerToken && !workspaceToken) {
        if (twilioData) {
            if (twilioData.workerToken) {
                twilioWorkerToken.set(twilioData.workerToken);
                setWorkerToken(twilioData.workerToken);
            }
            if (twilioData.workspaceToken) {
                twilioWorkspaceToken.set(twilioData.workspaceToken);
                setWorkspaceToken(twilioData.workspaceToken);
            }
            setHasTokenRenewed(true);
            setIsTokenExpired(false);
        }
    }

    const workerReady = (worker: IWorker) => {};

    const workerUpdated = useCallback(
        (worker: IWorker) => {
            // WORKER STATUS UPDATE
            const newStatus = worker?.activityName?.toLowerCase();

            if (currentStatus?.toLowerCase() !== newStatus) {
                workerStatus.set(newStatus);
                if (onStatusUpdate) {
                    onStatusUpdate(newStatus);
                }
            }
            // WORKER STATUS UPDATE
        },
        [currentStatus, onStatusUpdate]
    );

    const workerConnected = () => {};

    const workerDisconnected = () => {};

    const workerError = error => {};

    const tokenExpired = useCallback(() => {
        twilio.unregisterEvents();
        setWorkerToken(null);
        setWorkspaceToken(null);
        setWorkerObject(null);
        setWorkspaceObject(null);
        setIsTokenExpired(true);
    }, [twilio]);

    // FOR NOW WE WILL BE COMMENTING THIS AS WE DONT IMPLEMENT PUSH SUBSCRIPTION
    const reservationCreated = (reservation: IReservation) => {
        // const { taskSid, task } = reservation;
        // const { recordId, primaryMdn, secondaryMdn } =
        //     task && task.attributes
        //         ? task.attributes
        //         : { recordId: null, primaryMdn: null, secondaryMdn: null };
        // if (recordId && taskSid && primaryMdn && secondaryMdn) {
        //     setNewTask({ taskSid, recordId, primaryMdn, secondaryMdn, reservation });
        // }
    };

    // FOR NOW WE WILL BE COMMENTING THIS AS WE DONT IMPLEMENT PUSH SUBSCRIPTION
    const reservationAccepted = useCallback((reservation: IReservation) => {
        // const { primaryMdn, secondaryMdn } = newTask;
        // setAcceptedTask({ primaryMdn, secondaryMdn });
        // setNewTask(null);
    }, []);

    // FOR NOW WE WILL BE COMMENTING THIS AS WE DONT IMPLEMENT PUSH SUBSCRIPTION
    const reservationTimeout = (reservation: IReservation) => {
        // setNewTask(null);
    };

    // FOR NOW WE WILL BE COMMENTING THIS AS WE DONT IMPLEMENT PUSH SUBSCRIPTION
    const reservationCancelled = (reservation: IReservation) => {
        // setNewTask(null);
    };

    const reservationRejected = (reservation: IReservation) => {};

    const acceptReservation = useCallback(() => {
        const { reservation } = newTask;

        // We will replace this with API endpoint for accept reservation
        reservation.accept(function (error, reservation) {
            if (error) {
            }
        });
    }, [newTask]);

    const rejectReservation = useCallback(() => {
        const { reservation } = newTask;
    }, [newTask]);

    const startTwilio = useCallback(() => {
        const worker = twilio.initWorker(workerToken || '');
        if (hasTokenRenewed) {
            worker.updateToken(workerToken);
            setHasTokenRenewed(false);
        }
        setWorkerObject(worker);

        const twilioEvents: any = [
            {
                eventType: TWILIO_EVENT.READY,
                callback: workerReady,
            },
            {
                eventType: TWILIO_EVENT.UPDATE,
                callback: workerUpdated,
            },
            {
                eventType: TWILIO_EVENT.CONNECTED,
                callback: workerConnected,
            },
            {
                eventType: TWILIO_EVENT.DISCONNECTED,
                callback: workerDisconnected,
            },
            {
                eventType: TWILIO_EVENT.ERROR,
                callback: workerError,
            },
            {
                eventType: TWILIO_EVENT.TOKEN_EXPIRED,
                callback: tokenExpired,
            },
            {
                eventType: TWILIO_EVENT.RESERVATION_CREATED,
                callback: reservationCreated,
            },
            {
                eventType: TWILIO_EVENT.RESERVATION_ACCEPTED,
                callback: reservationAccepted,
            },
            {
                eventType: TWILIO_EVENT.RESERVATION_TIMEOUT,
                callback: reservationTimeout,
            },
            {
                eventType: TWILIO_EVENT.RESERVATION_CANCELLED,
                callback: reservationCancelled,
            },
            {
                eventType: TWILIO_EVENT.RESERVARTION_REJECTED,
                callback: reservationRejected,
            },
        ];
        twilio.registerEvents(twilioEvents);

        let activitySids = {};
        worker?.activities?.fetch((error, activityList) => {
            let activities = activityList?.data;
            let i = activities?.length;
            while (i--) {
                activitySids[activities[i].friendlyName] = activities[i].sid;
            }
            setActivityList(activitySids);
        });

        setWorkerRegistered(true);
        setIsTokenExpired(false);
    }, [
        workerToken,
        hasTokenRenewed,
        tokenExpired,
        twilio,
        workerUpdated,
        reservationAccepted,
    ]);

    useEffect(() => {
        if (workspaceToken && !workspaceObject) {
            const workspace = twilio.initWorkspace(workspaceToken);
            setWorkspaceObject(workspace);
        }

        if (workspaceObject && workerToken && !workerObject) {
            startTwilio();
            setIsTokenExpired(false);
        }
    }, [workspaceToken, workerToken, workspaceObject, workerObject, twilio, startTwilio]);

    useEffect(() => {
        if (activityList) {
            workerActivityList.set(activityList);
        }
    }, [activityList]);

    useEffect(() => {
        if (acceptedTask) {
            setAcceptedTask(null);
        }
    }, [acceptedTask]);

    return (
        <>
            {acceptedTask && acceptedTask?.primaryMdn && acceptedTask.secondaryMdn && (
                <Navigate
                    to={
                        AppRoutes.ENROLLMENT +
                        `/${acceptedTask.primaryMdn}/${acceptedTask.secondaryMdn}`
                    }
                    replace
                ></Navigate>
            )}

            {workerRegistered ? (
                <Box height="100%" id={'twilio-shell'} bgColor="asuWhite.200">
                    {children}
                </Box>
            ) : (
                <VerticalCenter innerProps={{ w: 'auto', m: '0', borderRadius: '8px' }}>
                    <Spinner variant={'pageLoader'} />
                </VerticalCenter>
            )}

            <ConfirmDialog
                open={newTask ? true : false}
                title={`Incoming New Task`}
                content={
                    <Flex direction={'column'}>
                        <Text fontSize={'18px'} fontWeight={'700'} pb={'12px'}>
                            You have new task
                        </Text>
                        <Box pb={'12px'}>
                            <Text variant={'fieldLabel'} pb={'6px'}>
                                Record ID
                            </Text>
                            <Text variant={'fieldValue'}>{newTask?.recordId}</Text>
                        </Box>
                        <Box pb={'12px'}>
                            <Text variant={'fieldLabel'} pb={'6px'}>
                                Primary MDN
                            </Text>
                            <Text variant={'fieldValue'}>{newTask?.primaryMdn}</Text>
                        </Box>
                        <Box pb={'12px'}>
                            <Text variant={'fieldLabel'} pb={'6px'}>
                                Secondary MDN
                            </Text>
                            <Text variant={'fieldValue'}>{newTask?.secondaryMdn}</Text>
                        </Box>
                    </Flex>
                }
                onYes={acceptReservation}
                onCancel={rejectReservation}
                yesLabel={'Accept'}
                noLabel={'Reject'}
            />
        </>
    );
};

export default TwilioShell;
